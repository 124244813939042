@import '../sass-vars/coach-app.scss';

.listPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
  }

.listgroup-client {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 10px;

}

.listgroup {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;

  .listActions {
      position: relative;
      display: flex;
      justify-content: flex-end;
    align-items: center;
      grid-row: 1/2;
    }
    button {
      height: 50px;
    }
}
.list-row:hover {
  background-color: $alphamainlight;
}

.list-row-item {
  margin: auto 0px !important;
  padding: 5px;
}
@media(max-width: $small) {
  .listDiv {
    position: relative;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 100%;
    grid-template-rows: 70px 1fr;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .list-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
  }

  .list-body-client {
    background-color: none;
    overflow-y: auto;
    padding: 10px;
    height: 100%;
    width: 100%;
    grid-row: 1/3;
  }
    .list-header-row {
    width: 100%;
    height: 60px;
    background-color: $soliddark;
    border-radius: $borderradius;
    display: grid;
    grid-template-columns: 35% 30% 25% 10%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $lightText;
  }
  .listgroup-client {
    .list-header-row {
      grid-template-columns: 45% 32.5% 22.5%;
    }
  }

  .list-row {
    width: 100%;
    min-height: 60px;
    background-color: white;
    border-radius: $borderradius;
    border: $lightBorder;
    display: grid;
    grid-template-columns: 35% 30% 25% 10%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $darkText;
  }
  .listgroup-client {
    .list-row {
      grid-template-columns: 45% 32.5% 22.5%;
    }
  }
  .list-header-row-clients {
    width: 100%;
    height: 60px;
    background-color: $soliddark;
    border-radius: $borderradius;
    display: grid;
    grid-template-columns: 15% 30% 50% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $lightText
  }

  .list-row-clients {
    width: 100%;
    min-height: 60px;
    background-color: white;
    border-radius: $borderradius;
    border: $lightBorder;
    display: grid;
    grid-template-columns: 15% 30% 50% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $darkText;
  }

  .list-cell1 {
    grid-column: 1/3;
    grid-row: 1/2;
    word-wrap: break-word;
    padding: 0px 2px;
    display: flex;
    justify-content: space-between;
  }

  .list-cell2 {
    grid-column: 1/2;
    grid-row: 2/3;
    word-wrap: break-word;
    padding: 0px 2px;
  }

  .list-cell3 {
    grid-column: 2/3;
    grid-row: 2/3;
  
  }

}

.user-pill {
  height: 21px;
  text-align: center;
  font-size: small;
  border-radius: 10.5px;
  color: white;
  padding: 0px 8px;
  background-color: #06865e;
}

.user-pill-cont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  overflow-y: ellipsis;
  gap: 3px;
}

.table-div {
  width: 100%;
  height: calc(100vh - 155px);
}

.loading-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

@media(min-width: $small) {
  .listDiv {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px 1fr;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

    .list-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
  }

  .list-body-client {
    background-color: none;
    overflow-y: auto;
    padding: 10px;
    height: 100%;
    width: 100%;
    grid-row: 1/3;
  }
    .list-header-row {
    width: 100%;
    height: 50px;
    background-color: $soliddark;
    border-radius: $borderradius;
    display: grid;
    grid-template-columns: 30% 20% 20% 25% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $lightText;
  }
    .list-row {
    min-height: 60px;
    width: 100%;
    background-color: white;
    border-radius: $borderradius;
    border: $lightBorder;
    display: grid;
    grid-template-columns: 30% 20% 20% 25% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $darkText;
  }
    .list-header-row-clients {
    width: 100%;
    height: 50px;
    background-color: $soliddark;
    border-radius: $borderradius;
    display: grid;
    grid-template-columns: 10% 30% 30% 25% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $lightText;
  }
    .list-row-clients {
    width: 100%;
    min-height: 60px;
    background-color: white;
    border-radius: $borderradius;
    border: $lightBorder;
    display: grid;
    grid-template-columns: 10% 30% 30% 25% 5%;
    grid-template-rows: 100%;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
    color: $darkText;
  }

}

@media (min-width: $small) and (max-width: $medium) {
  .client-list-cell2 {
    grid-column: 2/3;
    grid-row: 1/2;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    padding: 10px 20px;
  }

  .client-row-item-4 {
    display: none;
  }
}
.item1{
  grid-column: 1/2;
}
.item2{
  grid-column: 2/3;
}
.item3{
  grid-column: 3/4;
}
.item4{
  grid-column: 4/5;
}
.item5{
  grid-column: 5/6;
}