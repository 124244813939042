@import './sass-vars/coach-app.scss';


.sidebar-expanded {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 100px auto 100px;
  .user-message {
    display: flex;
    justify-content: center;
  }
  .top-container {
    padding-top: 10px;
  }
  .icon {
    color: white;
  }
  .link {
    color: white;
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 10px 10px;
  }
  .sideBarMain {
    position: relative;
    display: flex;
    flex-direction: column;
    .user {
      position: relative;
      text-align: center;
      order: 0;
      height: 10%;
      h2 {
        color: $soliddark;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .mainMenu {
      position: relative;
      width: 100%;
      padding: 0px;
      order: 1;
      border: none;
      list-style: none;
      li {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
          align-items: center;
        cursor: pointer;
        .selectedCont{
          display: flex;
          height: 44px;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: $solidlight;
          border-radius: 8px;
          .icon {
            color: $soliddark;
            
            transition: 0.4s;
          }
          .link {
          color: $soliddark;
            
          }
        }
        .itemCont {
          display: flex;
          height: 44px;
          border-radius: 50%;
          width: 100%;
          justify-content: center;
          align-items: center;
          .icon {
            transition: 0.4s;
          }
          .link {
            width: 100%;
          }
        }
      }
      li:hover {
        .itemCont {
          .icon {
            color: $background;
          }
          .link {
            color: $solidmid;
          }
        }
      }
    }
    .menu2 {
      position: relative;
      order: 2;
      border: none;
      padding: 0px;
      height: 10%;
      list-style: none;
      width: 100%;
      li {
        width: 100%;
        padding: 5px;
        height: 45px;
        cursor: pointer;
        .itemCont {
          height: 100%;
          display: flex;
          justify-content: space-evenly;
          .icon {
            transition: 0.4s;
          }
          .link {
            width: 100%;
          }
        }
      }
      li:hover {
        .itemCont {
          .icon {
            color: $solidmid;
          }
          .link {
            color: $solidmid;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $medium) {
  .menu-text {
    display: none;
  }
  .link{
    justify-content: center;
  }
  
}

.sideBarClosed {
  display: none;

}
