@import '../sass-vars/coach-app.scss';

// SMALL SCREEEN (MOBILE)


  .input_type {
    position: relative;
    width: 100%;
    
  }

    .setInput {
      border: none;
      background-color: white;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    
  

.cardInputs {
  width: 100%;

  .css-i4bv87-MuiSvgIcon-root{
    width: 30px;
  }
  .css-1wc0u5o-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }
}

.sets {
  overflow-y: auto;
  max-height: 150px;
  margin-top: 3px;

}

.inputCell {
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 100%;
  flex: 1; 
  padding: 3px;
  .input-cell-cell {
    height: 20px;
      border: $lightBorder;
      border-radius: 5px;
      background: #eee;
    display: grid;
    grid-template-columns: 50% 50%;
    width:95%;
  }
  span{
    width:100%;
    line-height: 20px;
  }
  
  

}
.sideCell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  text-align: center;
}
.add {
  width: 100px;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  .button {
    height: 25px;
    padding: 0%;
    margin-top: 2px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.columnHeader {
  padding: 0px;
}
.sortIcon {
  display: none;
}
