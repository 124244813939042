@import '../../style/sass-vars/coach-app.scss';
@media(max-width: $small) {
  .client-card {
    position: relative;
    width: 100%;
  }
}

// SMALL SCREEEN (MOBILE)
@media(min-width: $small) {
  .client-card {
    position: relative;
    width: 100%;
  }
}

.client-card {
  background-color: white; 
  border-radius: 6px;
  border: $lightBorder;
  .cardComment {
    margin: 5px 2.5px;
    width: 100%;
    resize: none;
    border-color: $lightBorder;
  }
  .cardHead {
    position: relative;
    width: 100%;
    align-items: center;
    padding-top: 5px;
    display: grid;
    grid-template-columns: 60px 1fr ;
  
    .cardNo {
      position: relative;
      background-color: $soliddark;
      text-align: center;
      border-radius: 50%;
      border: none;
      height: 42px;
      width: 42px;
      color: white;
      font-size: 1.3em;
      font-weight: bold;
    }
    .head_item {
      display: flex;
      justify-content: center;
      
    }
    .showCard {
      position: relative;
      text-align: center;
      background: $solidmid;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin: 5px;
      color: white;
      border: none;
      cursor: pointer;
    }
    .exitbutton {
      position: relative;
      text-align: center;
      background: $solidmid;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin: 5px;
      color: white;
      border: none;
      cursor: pointer;
    }
  }
  
  .cardBody {
    position: relative;
    padding: 3px;
    width: 100%;
    
  }
  .variation-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 5px 10px;
    margin-top: 5px;
    width: 100%;
  }
  .variation {
    font-size: 1rem;
    flex: 1;
    min-width: 150px;
    width: 180px;
    max-width: calc(50% - 10px);
    min-height: 28px;
  }
  .variation p {
    flex: 1; 
    text-align: center;
    font-size: .8rem;
    line-height: 32px;
    min-width: 65px;
    height: 32px;
    border: none;
    width: 100%;
    background: #eee;
    border-radius: 4px;
    text-align: center;
  }
  .variation span {
    font-size: .8rem;
    line-height: 1rem;
  }
  
}

