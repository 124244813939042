@import './sass-vars/coach-app.scss';
// TRY LIGHT GREEN
.App {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
}
.MuiButton-root{
  box-shadow: none !important;
}
// SMALL SCREEEN (MOBILE)
// Fit App
// OTHER COMPONENTS
.button {
  position: relative;
  text-align: center;
  background: $soliddark;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  height: 30px;
  border: none;
  color: white;
  min-width: 45px;
  cursor: pointer;
}
.button:hover {
  background: $soliddark;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
  min-width: 45px;
  cursor: pointer;
}
.button__light {
  position: relative;
  text-align: center;
  background: $solidmid;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  height: 30px;
  border: none;
  color: white;
  min-width: 45px;
  cursor: pointer;
}
// Topbar
.topbar {
  position: relative;
  height: 100%;
  width: 100%;
  background: $solidmid;
  display: grid;
  grid-template-columns: 80px 1fr minmax(60px, 100px);
  .leftContainer {
    grid-column-start: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 135px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
    }
  }
  .centerContainer {
    height: 100%;
    grid-column-start: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .mainMenu {
      position: relative;
      width: 100%;
      padding: 0px;
      display: flex;
      align-items: center;
      border: none;
      height: 100%;
      list-style: none;
      li {
        cursor: pointer;
        height: 100%;
        margin-right: 30px;
        display: flex;
        align-items: center;
        .itemCont {
          width: 100%;
          .link {
            color: #efefef;
            width: 90%;
          }
        }
      }
      li:hover {
        .itemCont {
          .link {
            font-weight: bolder;
            text-decoration: none;
            color: #efefef;
          }
        }
      }
    }
  }
  .rightContainer {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-start: 3;
    .link {
      color: #efefef;
    }
  }
}

// Confirmation

// Exercise
.exercise {
  position: relative;
  background: white;
  width: 90vw;
  max-width: 600px;
  top: 25%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  .exerciseName {
    display: flex;
    justify-content: space-between;
  }
  .exerciseDetails {
    width: 100%;
  }
  .exerciseInstructions {
    width: 100%;
  }
  .exerciseMedia {
    width: 100%;
  }
}
// Pagination
.pagination {
  display: flex;
  justify-content: space-between;
  width: 30%;
  max-width: 300px;
  background: white;
  border-radius: 2px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  .previous {
    height: 30px;
    padding: 5px;
    background: $solidmid;
    color: white;
    text-align: center;
    border-right: $lightBorder;
    cursor: pointer;
    .icon {
      height: 100%;
      font-size: large;
      font-weight: bold;
    }
  }
  .numbers {
    .number {
      height: 20px;
      width: 20px;
      text-align: center;
      background: white;
      color: $solidmid;
      font-weight: bold;
      border-right: $lightBorder;
      border-left: $lightBorder;
    }
  }
  .next {
    height: 30px;
    padding: 5px;
    background: $solidmid;
    color: white;
    text-align: center;
    border-left: $lightBorder;
    cursor: pointer;
    .icon {
      height: 100%;
      font-size: large;
      font-weight: bold;
    }
  }
}
// Nav Stuff
.navStuff {
  position: relative;
  min-width: 200px;
  display: flex;
  align-items: flex-start;
  .button {
    margin-left: 0;
  }
}

.sideInfo {
  position: fixed;
  display: inline-block;
  background: white;
  border-radius: 2px;
  top: 60px;
  bottom: 5px;
  right: 5px;
  width: 300px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.calculator {
  position: fixed;
  display: inline-block;
  background: white;
  border-radius: 2px;
  top: 60px;
  bottom: 5px;
  right: 5px;
  width: 300px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.sideButtons {
  position: fixed;
  display: flex;
  z-index: 1;

  .button1 {
    position: relative;
    height: 30px;
    padding: 0px;
    width: 110px;
    background: $solidmid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
    color: #ffffff;
    margin-right: 1px;
    cursor: pointer;
  }
  .button2 {
    position: relative;
    height: 30px;
    padding: 0px;
    width: 110px;
    background: $solidmid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    div {
      position: relative;
    }
  }
  button:hover {
    background: $soliddark;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}
// hidden desktop 
@media (min-width: $medium) {
  .hidden-desktop {
    display: none;
  }
}

@media (max-width: $medium) {
  .hidden-mobile {
    display: none;
  }
}
@keyframes slidein {
  0% {
      bottom: -75px;
      opacity: 0;
  };
  30% {
      bottom: 30px;
      opacity: 1;
  };
  70% {
    bottom: 30px;
    opacity: 1;
  };
  100% {
      bottom: -75px;
      opacity: 0;
  };
}
.card {
  background-color: white;
  border-radius: $borderradius;
  box-shadow: $box-shadow;
  border: $lightBorder;
  padding: 10px;
  flex: 1;
}
