@import '../sass-vars/coach-app.scss';

  .session {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cardsCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }
  .session-footer {
    width: 95%;
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .save-button {
  }
  .text {
  }

