@import '../sass-vars/coach-app.scss';
// SMALL SCREEEN (MOBILE)
@media (max-width: $small) {
  
  .client-form {
    height: 100%;
    width:100% ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .client-form-controls {
    height: 100%;
    width:100% ;
    padding: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(90px);
    gap: 5px;
  }
  .form-buttons {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

// DESKTOP
@media (min-width: $small) {
  .client-form {
    height: 100%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .client-form-controls {
    height: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 90px 90px;
    gap: 5px;
  }
  .form-buttons {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

  .client-form-cont {
  }
  

