@import '../sass-vars/coach-app.scss';
// mobile styles media query

.programHead {
  
  .program-form-cont {
    height: 100%;
    width: 350px;
    overflow: hidden;
  }
  .program-form {
    min-height:0px;
  }
}

  .program-form-cont {
    height: 100%;
    width: 350px;
    overflow: hidden;
  }
  .form-parent {
    height: 100%;
    width: 700px;
    display: flex;
  }
  .program-form {
    min-height: 400px;
    display: flex;
    width: 350px;
    flex-direction: column;
    justify-content: space-between;
  }
  .workout-form {
    min-height: 400px;
    display: grid;
    width: 350px;
    grid-template-rows: 200px 1fr 50px;
  }
  .program-form-controls {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 25% 25% 25% 25%;
    gap: 5px;
  }
  .form-buttons {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker-popper {
    z-index: 1500 !important;
  }
  .program-form button {
    line-height: 1.3;
    width: min-content;
  }
  .workout-questions {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: $small) {
    .program-form-controls{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .program-form-cont {
      width: 100%;
    }
    .form-parent {
      width: 200%;
    }
    .program-form {
      width: 100%;
    }
    .workout-form {
      width: 100%;
      grid-template-rows: 200px 1fr 60px;
    }

  }
