$small: 576px;
$medium: 768px;
$large: 1080px;
$menuitems: white;

$lightText: #EFEFEF;
$darkText: #333;

$lightBorder: 1px solid #ddd;
$midBorder: 1px solid #999;
$darkBorder: 1px solid #555;

$background: #f2f2f2;
$background_dark: #07383F;
$backgroundTranpartent: #F2F2F299;

$borderradius: 4px;
$box-shadow: 0px;

$accent: #E34404;

$soliddark: #07383F;
$solidmid: #15998E;
$solidlight: #dfffff;

$alphamainlight: #c8e8e544;

$alphadark: #07383F44;
$alphamid: #15998E44;
$alphalight: #A4DFD744;



$primary : $solidmid;

@import "bootstrap";

.dark {
  background-color: $soliddark;
  color: $lightText;
}
.mid {
  background-color: $solidmid;
  color: $lightText;
}
.light {
  background-color: white;
  color: $darkText;
}
.dark-text {
  color: $soliddark;
}
.common-box {
  border: $lightBorder;
  border-radius: $borderradius;
  box-shadow: $box-shadow;
}
@keyframes close {
  from {
      right: '320px';
  }
  to {
    right: '20px';
  }
}
@keyframes open {
  from {
    right: '20px';
  }
  to {
    right: '320px';
  }
}
