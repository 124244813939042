@import '../sass-vars/coach-app.scss';

  .unauthmain {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: $background;
    .loginMenu {
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        width: 250px;
        height: 100px;
        font-size: x-large;
      }
    }
    form {
      display: grid;
      grid-template-rows: 17.5% 17.5% 20% 20% 25%;
      grid-template-columns: 100%;
      align-items: center;
      max-width: 400px;
      width: 100%;
      max-height: 800px;
      height: 80%;
      background: white;
      padding: 10px;
      border-radius: 8px;
      box-shadow: $box-shadow;

    }
    .form-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
    }
    .formTitle {
      width: 100%;
      text-align: center;
      
    }
    .button {
      position: relative;
      text-align: center;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      padding: 5px;
      margin: 10px;
      height: 35px;
      border: none;
      font-weight: 600;
      min-width: 80px;
      cursor: pointer;
    }
    .button:hover {
      background: $soliddark;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: white;
      cursor: pointer;
    }
    .form-title {
      font-size: 3em;
      color: #222222;
    }
    .login-form {
      position: relative;
      width: 100%;
    }
    .signup-form {
      position: relative;
      width: 100%;
    }
  }

