@import '../../style/sass-vars/coach-app.scss';

// SMALL SCREEEN (MOBILE)
// Sets
@media (max-width: 576px) {
  .input_type {
    position: relative;
    width: 100%;
    
  }


    .setInput {
      border: none;
      background-color: white;
      width: 60px;
      height: 100%;
      text-align: center;
    }
    

}
@media (min-width: 576px) {
  .input_type {
    position: relative;
    width: 100%;
    
  }

    .setInput {
      border: none;
      background-color: white;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    
  
}
.cardInputs {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  .css-i4bv87-MuiSvgIcon-root{
    width: 30px;
  }
  .css-1wc0u5o-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }
}

.sets {
  overflow-y: auto;
  max-height: 150px;
  margin-top: 3px;

}

.inputCell {
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 100%;
  flex: 1; 
  padding: 3px;
  // .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  //   height: 18px;
  //   text-align: center;
  //   border: none;
  //   background: #eee;
  //   border-radius: 4px;
  // }
  // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  //   border: none;
  // }
  

}
.sideCell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  text-align: center;
}
.add {
  width: 100px;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  .button {
    height: 25px;
    padding: 0%;
    margin-top: 2px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.columnHeader {
  padding: 0px;
}
.sortIcon {
  display: none;
}
