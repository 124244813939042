@import '../sass-vars/coach-app.scss';

  // Clients
  .clients {
    padding: 10px;
    height: 100%;
    width: 100%;
    .clientTableCont {
      position: relative;

      width: 90%;
      height: 90%;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      border: 1px solid #ccc;
      .tableTitle {
        font-size: x-large;
        font-weight: 600;
      }
      .ag-theme-alpine {
        height: 100%;
      }
      AgGridReact {
        height: calc(100% - 10px);
        overflow-y: auto;
      }
    }
  }

