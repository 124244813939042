@import '../sass-vars/coach-app.scss';
  .button_cont {
    display: flex;
    gap: 15px;
  }
  .program-container::-webkit-scrollbar {
    display: none;
  }
  .program-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $background;
    display: flex;
    flex-direction: column;
    align-items: center;
    .navStuff {
      padding: 0px 10px 0px 10px;
      width: 100%;
    }
    .program {
      width: 100%;
    }
    // Program Head
    .programHead {
      position: relative;
      width: calc(100% - 20px);
      margin: 3px 0px;
      text-align: center;
      background-color: white;
      border-radius: $borderradius;
      box-shadow: $box-shadow;
      border: $lightBorder;
      .program-form-cont {
        margin: 10px;
        height: 170px;
        width: calc(100% - 20px);
      }
      .program-title {
        .title-input {
          max-width: 100%;
          text-align: center;
          border: none;
        }
      }
      .program-form {
        height: 100%;
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 100%;
        grid-template-rows: 70px 60px 40px;
      }
      .program-form-controls {
        width: 100%;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 5px;
      }
      .form-buttons {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .react-datepicker-popper {
        z-index: 1500 !important;
      }
    }
    .programBody {
      display: flex; 
      flex-direction: column; 
      align-items: center; 
      width: 100%;
      overflow-y: visible;
      overflow-x: hidden;
    }
    // WORKOUTS
    .workouts {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      .css-1ocpf3a-MuiButtonBase-root-MuiTab-root {
        min-height: 0px;
      }
      .buttons {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        .button {
          position: relative;
          text-align: center;
          background: $solidmid;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          padding: 5px;
          margin: 5px;
          height: 30px;
          border: none;
          color: #ffffff;
          cursor: pointer;
        }
        .button:hover {
          background: $soliddark;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border: none;
          color: white;
          cursor: pointer;
        }
        input {
          position: relative;
          background-color: $solidmid;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          text-align: center;
          border-radius: 2px;
          border: none;
          padding: 5px;
          margin: 5px;
          height: 30px;
          border: none;
          color: white;
          width: 80px;
          color: white;
        }
      }
    }
    .program-config-cont {
      width: calc(100% - 20px); 
      display: flex;
    }
    .config-scroll{
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2; 
      width: 55px;
      background: none;
    }
    .program-config { 
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      overflow-y: auto;
      width: 100%;
      .config-item{
        min-width: 130px;
        width: 14.25%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        .config-item-title {
          width: 100%;
          height: 50px;
          text-align: center;
          border-radius: 6px;
          box-shadow: $box-shadow;
          color: #eee;
          background-color:$soliddark;
          padding: 0 5px;
          line-height: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .config-item-pill {
          display: grid;
          text-align: center;
          border-radius: 6px;
          padding: 8px;
          box-shadow: $box-shadow;
          width: 100%;
          height:fit-content;
          margin: -5px -5px 0px;
        }
        .config-item-pill-cont {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          padding: 0px;
        }
        .config-item-pill-actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .program-footer{
     padding: 0px;
      width: 100%;
      height: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }
  }

