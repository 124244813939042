@import '../sass-vars/coach-app.scss';


  .controls{
    display: flex;
    gap: 30px;
  }
  .warmup-cooldown {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding-top: 10px;
    .card {
      min-width: 300px;
    }
    .text-edit {
      width: calc(100% - 30px)
    }
    .showWUCD {
      display: none;
    }
  }
  


@media (max-width: $large) {
  .controls{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .warmup-cooldown {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    .text-edit {
      width: calc(100% - 30px)
    }
    .showWUCD {
      display: none;
    }
  }
}
.workout {
  width: calc(100% - 20px);
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  
}

