@import '../sass-vars/coach-app.scss';

// DESKTOP

.print-workout-cont {
    width:100%;
    page-break-before: always;
    border-bottom:$lightBorder;
    min-height: 1100px;
    .print-workout-details {
        
        width:100%;
        .warmupCooldown {
            width: 100%;
            display:flex;
            justify-content:space-between;
            margin-left: auto;
            margin-right: auto;
            .showWUCD{
                display: none;
            }
            .variable {
                text-align: center;
                display:flex;
                flex-direction: column;
                .textarea {
                    border:1px solid #bbb;
                    border-radius:2px;
                    background: white;
                    min-height: 100px;
                    width: 420px;
                    resize: none;
                    padding: 10px;
                }
            } 
        }
        
    }   
    
}
@media print {
    .print-workout-cont {
        border-bottom:none;
        min-height: 0px;
        padding: 20px;
    }
    .print-cards-cont {
        page-break-inside:avoid;
    }
    .print-session-cont {
        display: block;
        page-break-inside:auto;
    }
}
.print-cards-cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    
  }
.print-session-cont {
    margin: 0px 15px;
    margin-top: 20px;
    
}
