@import '../sass-vars/coach-app.scss';

  .dashContainer {
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: auto;
    .dashItem {
      height: auto;
      min-width: 200px;
      width: 90%;
      margin: 10px;
      background: white;
      font-size: small;
    }
    .one {
      background-color: red;
    }
    .two {
      background-color: blue;
    }
    .three {
      background-color: green;
    }
    .four {
      background-color: purple;
    }
  }

